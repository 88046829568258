<template>
  <v-card
    :loading="loading"
    :disabled="loading"
    class="mb-1"
    outlined
    >
    <v-card-title>
      Métodos de envío
    </v-card-title>

    <v-card-text
      :key="shippings.length"
      >
      <template
        v-for="(order, idx) in currentOrder.children"
        >
        <div
          class="mb-3 pa-3 rounded-lg grey lighten-4"
          :key="shippings[idx].id"
          >
          <div
            class="text-subtitle-1 primary--text font-weight-medium mb-1"
            >
            <v-icon
              color="primary"
              class="mt-n1"
              >mdi-storefront</v-icon> {{ order.store.name }}
          </div>
          <div
            :key="costs[idx]"
            >
            <v-select
              v-model="shippings[idx]"
              :items="order.shippingMethods"
              :hint="costs[idx]"
              persistent-hint
              item-text="name"
              item-value="id"
              label="Métodos disponibles"
              return-object
              single-line
              outlined
              flat
              solo
              dense
              ></v-select>
          </div>

          <v-dialog
            v-if="!(shippings[idx].name == 'Sendcargo')"
            v-model="dialog"
            width="500"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                x-small
                outlined
                v-bind="attrs"
                v-on="on"
                >
                Ver términos y condiciones
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                Términos y condiciones
              </v-card-title>

              <v-card-text
                v-html="shippings[idx].description"
                ></v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { Create } from '@/graphql/mutations/shipments'
import { CalculateShipmentCost } from '@/graphql/queries/shipments'

export default {
  data: () => ({
    shippings: [],
    costs: [],
    loading: false,
    dialog: false
  }),

  created () {
    this.currentOrder.children.forEach( (order, idx) => {
      this.shippings[idx] = order.shippingMethods[0]
      this.amount(this.shippings[idx])
    })
  },

  watch: {
    shippings: {
      deep: true,

      handler (val) {
        this.shippings.forEach ( (shipping, idx) => {
          this.amount(shipping)
        })
      }
    }
  },

  props: {
    currentOrder: {
      required: true,
      type: Object,
    }
  },

  methods: {
    amount (shipping) {
      var formatter = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      var order = this.currentOrder.children.find( child => child.storeId == shipping.storeId )
      var index = this.currentOrder.children.indexOf(order)

      if (shipping.name == 'DITUCI') {
        var amount = order.lineItemsAmount * parseFloat(shipping.preferences.commission) / 100
        
        this.costs[index] = `Valor del envío: ${formatter.format(amount)}`
      } else if (shipping.name == 'Sendcargo') {
        // Calculate amount for order
        this.$apollo.query({
          query: CalculateShipmentCost,
          variables: {
            id: order.id
          },
          fetchPolicy: 'no-cache'
        }).then ( res => {
          this.costs[index] = `Valor del envío: ${formatter.format(res.data.calculateShipmentCost.price)}`
          this.$forceUpdate()
        })
      } else {
        this.costs[index] = `Envío a cargo de ${order.store.name}.`
        if (shipping.toPay) {
          this.costs[index] += " Se subirá comprobante de envío donde podrá hacer seguimiento del mismo y ver el costo por pagar al momento del retiro."
        } else {
          this.costs[index] += ` Valor del envío: ${ formatter.format(shipping.price) }`
        }
      }

      this.$forceUpdate()
    },

    next () {
      this.loading = true
      this.shippings.forEach( (shipping, idx) => {
        var order = this.currentOrder.children[idx]

        this.$apollo.mutate({
          mutation: Create,
          variables: {
            input: {
              shippingMethodId: shipping.id,
              orderId: order.id
            }
          }
        }).then( res => {
          if (idx == this.shippings.length - 1) {
            this.loading = false
            this.$emit('next')
          }
        })
      })
    }
  }
}
</script>
