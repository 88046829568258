import gql from 'graphql-tag'

export const Create = gql`
  mutation createShipment($input: CreateShipmentInput!) {
    createShipment(input: $input) {
      success
    }
  }
`

export const Edit = gql`
  mutation editShipment($input: EditShipmentInput!) {
    editShipment(input: $input) {
      success
    }
  }
`
